<template>
  <v-form ref="userform" lazy-validation v-model="valid">
    <v-container>
      <v-row no-gutters>
        <!-- NOMBRE -->
        <v-col cols="12">
          <v-text-field v-model="form[keys.name]" :rules="rules.nameRules" outlined dense label="Nombre"></v-text-field>
        </v-col>
        <!-- PRIMER APELLIDO -->
        <v-col cols="6" class="pr-3">
          <v-text-field v-model="form[keys.lastName1]" :rules="rules.lastName1Rules" outlined dense
            label="Primer apellido"></v-text-field>
        </v-col>
        <!-- SEGUNDO APELLIDO -->
        <v-col cols="6" class="pl-3">
          <!-- :rules="rules.lastName2Rules" Solo si se requiere -->
          <v-text-field v-model="form[keys.lastName2]" outlined dense label="Segundo apellido"></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="mb-6"></v-divider>

      <!-- NOMBRE DE USUARIO O RUT -->
      <v-text-field v-model="form[keys.username]" :rules="rules.rutRules" outlined dense label="Número de documento"
        :disabled="update" persistent-hint
        hint="El nombre de usuario es establecido por el número de documento."></v-text-field>

      <!-- CORREO ELECTRONICO -->
      <v-text-field v-model="form[keys.email]" :rules="rules.emailRules" outlined dense
        label="Correo electrónico"></v-text-field>

      <!-- TELEFONO -->
      <v-text-field v-model="form[keys.phone]" :rules="rules.phoneRules" outlined dense
        label="Número de Teléfono"></v-text-field>

      <!-- <v-divider class="mb-6"></v-divider>

      <v-select
        :items="accessUrlList"
        v-model="form.convenio"
        item-value="url"
        item-text="nombre"
        outlined
        dense
        multiple
        clearable
        label="Urls de Acceso (opcional)"
        persistent-hint
        hint="Seleccione las urls de las aplicaciones que desea dar acceso al usuario."
      ></v-select> -->

      <v-divider class="mb-6"></v-divider>

      <v-select :items="roles" v-model="form.roles" item-value="value" item-text="label" outlined dense multiple
        clearable label="Roles"></v-select>

      <v-select :items="roles_ids" v-model="form.roles_ids" item-value="id" item-text="nombre" value="role_id" outlined
        dense multiple clearable label="Permisos"></v-select>

      <v-select :items="modulos" v-model="form.modulos" item-value="codigo" item-text="label" outlined dense multiple
        clearable label="Modulos"></v-select>

      <v-divider class="mb-6"></v-divider>

      <!-- RESPONSABLE DEL CANAL -->
      <!-- <v-checkbox
        v-if="!loading"
        v-model="form[keys.channel.channel][keys.channel.owner]"
        label="¿Es responsable del canal?"
        :true-value="true"
        :false-value="false"
      ></v-checkbox> -->

      <!-- CHECKBOX FALSO -->
      <!-- <v-checkbox
        v-else
        label="¿Es responsable del canal?"
        v-model="owner"
        :disabled="true"
      ></v-checkbox> -->

      <p>Credenciales</p>
      <v-row>
        <v-col cols="6">
          <v-switch v-model="hideClient" :true-value="true" :false-value="false"
            :label="hideClient == true ? 'Ocultar' : 'Mostrar'"></v-switch>
        </v-col>
        <v-col cols="6" align="end" align-self="center">
          <v-btn color="primary" :disabled="!hideClient" outlined @click="createKeys()">
            Generar
          </v-btn>
        </v-col>
      </v-row>

      <div class="pa-0" v-if="hideClient">
        <v-text-field v-model="form[keys.clientId]" outlined dense label="Client Id"></v-text-field>
        <v-text-field v-model="form[keys.clientSecret]" outlined dense label="Cliente Secret"></v-text-field>
      </div>

      <!-- SUBMIT -->
      <v-divider class="mb-6"></v-divider>

      <div class="text-center">
        <v-btn :disabled="!valid" color="primary" outlined @click="submitForm" :loading="loading">
          {{ update ? "Actualizar usuario" : "Guardar nuevo usuario" }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import { rutMask } from "@/store/resources/masks.js";
import { userKeys } from "@/store/resources/userkeys.js";
import { mapActions } from "vuex";
import AuthService from "@/services/manager/auth/app.service.js";

import {
  // validarRut,
  // validarRutFormat,
  validarEmail,
} from "@/store/resources/validate.js";
const Service = new AuthService();

export default {
  name: "AddChannelUserForm",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    keys: {
      type: Object,
      default: () => { },
    },
    // channel: {
    //   type: String,
    //   default: "",
    //   description: "En el caso de que el formulario amerite un canal de comparacion"
    // },
    formData: {
      type: Object,
      default: null,
    },
    accessUrlList: {
      type: Array,
      default: () => [],
      description: "Listado de las url de acceso para el usuario",
    },
    roles_ids: {
      type: Array,
      default: () => [],
      description: "Listado de los roles de acceso para el usuario",
    },
  },
  mounted() {
    this.verifyFormData(this.formData);
  },
  watch: {
    formData: function (newVal) {
      this.verifyFormData(newVal);
    },
  },
  data: () => ({
    valid: false,
    hideClient: false,
    update: false,
    form: {
      nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      celular: "",
      correo_electronico: "",
      usuario: "",
      convenio: [],
      roles: [],
      roles_ids: [],
      modulos: [],
      [userKeys.clientId]: "",
      [userKeys.clientSecret]: "",
      // canal: {
      //   responsable: false,
      // },
    },
    owner: false,
    rules: {
      nameRules: [(v) => !!v || "Debe escribir un nombre"],
      lastName1Rules: [(v) => !!v || "Debe especificar el primer apellido"],
      lastName2Rules: [(v) => !!v || "Debe especificar el segundo apellido"],
      phoneRules: [(v) => !!v || "Debe escribir un número de teléfono"],
      emailRules: [
        (v) => !!v || "Debe escribir un correo electrónico",
        (v) => validarEmail(v) || "El correo debe ser válido",
      ],
      rutRules: [
        (v) => !!v || "Debe escribir el número de documento de la persona",
        // (v) => validarRutFormat(v) || "Formato de RUT invalido",
        // (v) => validarRut(v) || "No se ha logrado reconocer el RUT",
      ],
    },
    roles: [
      {
        label: "Personas",
        value: "personas",
      },
      {
        label: "Descuentos",
        value: "descuentos",
      },
    ],
    modulos: [],
  }),
  computed: {
    rutMask: () => rutMask,
  },
  methods: {
    ...mapActions("Users", ["REQUEST_USER_ROLES"]),
    verifyFormData(formData) {
      if (formData) {
        this.setFormData(formData);
      } else {
        this.resetFormData();
      }
    },
    submitForm() {
      if (this.$refs.userform.validate()) {
        let payload = this.form;
        payload[this.keys.username] = payload[this.keys.username].replace(
          /[^A-Z0-9]/gi,
          ""
        );
        // this.form[this.keys.channel.channel][this.keys.channel.owner]
        //   ? (this.owner = true)
        //   : (this.owner = false);
        if (this.update) {
          this.$emit("update", {
            id: this.formData[this.keys.id],
            data: payload,
            channels: this.formData[this.keys.channel.channel],
          });
        } else {
          this.$emit("submit", payload);
        }
      }
    },
    getUserRoles(payload) {
      this.REQUEST_USER_ROLES(payload)
        .then((response) => {
          this.form[this.keys.roles_ids] = response ?? [];
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    getAplicacionesP() {
      this.loading = true;
      Service.getAplicacionesParaUsuario()
        .then((response) => {
          this.modulos = response.result;
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setFormData() {
      this.update = true;
      this.form[this.keys.name] = this.formData[this.keys.name];
      this.form[this.keys.lastName1] = this.formData[this.keys.lastName1];
      this.form[this.keys.lastName2] = this.formData[this.keys.lastName2];
      this.form[this.keys.phone] = this.formData[this.keys.phone];
      this.form[this.keys.email] = this.formData[this.keys.email];
      this.form[this.keys.username] = this.formData[this.keys.username];
      this.form[this.keys.agreement] = this.formData[this.keys.agreement];
      this.form[this.keys.modules] = this.formData[this.keys.modules];
      this.form[this.keys.roles] = this.formData[this.keys.roles];
      this.form[this.keys.clientId] = this.formData[this.keys.clientId];
      this.form[this.keys.clientSecret] = this.formData[this.keys.clientSecret];
      this.form[this.keys.roles_ids] = this.formData[this.keys.roles_ids];
      this.getUserRoles(this.formData);
      this.getAplicacionesP();

      // En caso de que amerite revisar si el usuario es responsable del canal pasado en las propiedades
      // let channel = this.formData[this.keys.channel.channel].find(
      //   (e) => e[this.keys.channel.id] === this.channel
      // );
      // if (channel && channel[this.keys.channel.owner]) {
      //   this.form[this.keys.channel.channel][this.keys.channel.owner] = true;
      //   this.owner = true;
      // } else {
      //   this.owner = false;
      // }
    },
    resetFormData() {
      this.update = false;
      this.form[this.keys.name] = "";
      this.form[this.keys.lastName1] = "";
      this.form[this.keys.lastName2] = "";
      this.form[this.keys.phone] = "";
      this.form[this.keys.email] = "";
      this.form[this.keys.username] = "";
      this.form[this.keys.agreement] = [];
      this.form[this.keys.modules] = [];
      this.form[this.keys.roles] = [];
      this.form[this.keys.clientId] = "";
      this.form[this.keys.clientSecret] = "";
      this.form[this.keys.roles_ids] = [];
    },
    createKeys() {
      this.form[this.keys.clientId] = this.$CryptoJS.AES.encrypt(
        this.form[this.keys.email],
        "id"
      ).toString();
      this.form[this.keys.clientSecret] = this.$CryptoJS.AES.encrypt(
        this.form[this.keys.name],
        "secret"
      ).toString();
    },
  },
};
</script>

<style></style>
